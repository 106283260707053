.modalstyle .modalheader {
    border-bottom: none;
}

.modalstyle .modalbody {
    border-bottom: none;
}

.modalstyle [class="modal-content"] {
    background: var(--cardbg);
    /* box-shadow: var(--themecolor) -1px 0px 14px; */
    /* border: 1px solid var(--themecolor); */
    min-width: 320px;
    max-width: 420px;
    margin: auto;
    border-radius: 0px;
    width: 100%;
    /* border-top-left-radius: 30px !important; */
}

.dangertext {
    color: red !important;
    font-size: 16px;
    font-weight: 500;
    text-align: start;
    margin-bottom: 20px;
}

.modalstyle .modalfooter {
    padding: 20px;
    border-top: 0px;
}

.modalstyle .btnstyle {
    background: var(--themecolor);
    border-radius: 7px;
    color: var(--whitetext);
    font-size: 12px;
    border: 1px solid var(--themecolor);
    width: 100%;
    height: 40px;
    font-size: 16px;
    font-weight: 600;
}

.modalstyle .btnstyle:hover {
    background: var(--btnhover);
    border: 1px solid var(--btnhover);
    color: #fff;
}

.modalstyle .modalheader .modaltitle {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.1;
    color: var(--whitetext);

}

.modalstyle .modalheader {
    display: flex;
    justify-content: space-between;
    padding: 25px;
}

.modalstyle .modalheader .closebtn {
    background: none;
    padding: 0px;
    border: none;
    color: var(--whitetext);
    cursor: pointer;
}

.modalstyle .modalbody {
    /* display: flex;
    gap: 20px;
    justify-content: space-evenly; */
    padding: 25px;

}

.modalstyle .modalbody .label {
    font-size: 12px;
    color: var(--whitetext);
}


.modalstyle .modalfooter .footerdesc {
    color: var(--whitetext);
    text-align: center;
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 10px;
}

.modalstyle .modalbody .innerhead {
    color: var(--themecolor);
    font-size: 14px;
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 24px;
    text-transform: uppercase;
}

.modalstyle .modalbody .innerheadwhite {
    color: var(--whitetext);
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 10px;
}

.modalstyle {
    padding-right: 8px !important;
}

.modalstyle .modalbody ul {
    list-style: none;
    padding-left: 0px;
}

@media screen and (max-width:400px) {
    .modalstyle .modalbody .innerheadwhite {
        font-size: 14px;
    }

    .modalstyle [class="modal-content"] {
        min-width: 280px;
        max-width: 280px;
    }

}







.inputsec {
    background: var(--bgcolor);
    padding: 12px;
    border-radius: 12px;
}

.inputsec .balancerow {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.inputsec .inputlabel {
    color: var(--whitetext);
    font-size: 14px;
}

.currencybtn {
    background: var(--cardbg);
    padding: 4px;
    color: var(--whitetext);
    font-size: 14px;
    border: none;
    display: flex;
    align-items: center;
    gap: 5px;
    padding-left: 10px;
    padding-right: 10px;
}

.inputsec .inputsecrow {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.inputsec input {
    background: none;
    border: none;
    color: var(--whitetext);
    width: 100%;
}

.inputsec input::placeholder {
    color: var(--whitetext);
}

.inputsec input:focus-visible {
    outline: none;
}

.inputsec input:focus {
    box-shadow: none;
    border: none !important;
}

.rightbtnsec {
    display: flex;
    justify-content: end;
    align-items: center;
    min-width: 155px;
}
.declabel{
    font-size: 14px;
    color: var(--themecolor);
    text-align:center;
    font-weight: 600;
    text-decoration: none;
    background: transparent;
    border: none;
}
.declabel:hover{
    color: var(--whitetext);

}

.cancelbtn {
    background: var(--cancel);
    white-space: nowrap;
    font-weight: 600;
    font-size: 16px;
    color: var(--whitetext);
    border: 1px solid var(--cancel);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
    transition: none 0s ease 0s;
    padding-top: 5px;
    width: 100%;
    padding-bottom: 5px;
    border-radius: 11px;
    height: 48px;
}

.cancelbtn:hover {
    background: rgb(119, 209, 255) !important;
    border: 1px solid rgb(119, 209, 255) !important;
    color: rgb(255, 255, 255) !important;
    transition: none 0s ease 0s !important;
}

.connectwalletbtn {
    background: var(--themecolor);
    white-space: nowrap;
    font-weight: 600;
    font-size: 16px;
    color: var(--whitetext);
    border: 1px solid var(--themecolor);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
    transition: none 0s ease 0s;
    padding-top: 5px;
    width: 100%;
    padding-bottom: 5px;
    border-radius: 11px;
    height: 48px;
}

.connectwalletbtn:hover {
    background: rgb(119, 209, 255) !important;
    border: 1px solid rgb(119, 209, 255) !important;
    color: rgb(255, 255, 255) !important;
    transition: none 0s ease 0s !important;
}

@media screen and (max-width:575px) {
    .rightbtnsec {
        justify-content: center;
    }

    .inputsec .inputlabel {
        text-align: center;
    }

    .inputsec .balancerow,
    .inputsecrow {
        flex-direction: column;
        gap: 10px;
    }

    .inputsec input {
        text-align: center;
    }
}


.modalslider .rangesec .rangeinput[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    cursor: pointer;
    outline: none;
    overflow: hidden;
    border-radius: 16px;
    height: 20px;
    margin-bottom: 20px;
}


.modalslider .rangesec .rangeinput[type="range"]::-webkit-slider-runnable-track {
    height: 15px;
    background: var(--bgcolor);
    border-radius: 16px;
}


.modalslider .rangeinput[type="range"]::-moz-range-track {
    height: 15px;
    background: var(--bgcolor);
    border-radius: 16px;
}


.modalslider .rangesec .rangeinput[type="range"]::-webkit-slider-thumb {

    -webkit-appearance: none;
    appearance: none;
    height: 20px;
    width: 20px;
    background-color: var(--whitetext);
    border-radius: 50%;
    border: 1px solid var(--whitetext);
    box-shadow: -407px 0 0 400px var(--themecolor);
    margin-top: -3px;
}


/* Thumb: Firefox */
.modalslider .rangesec .rangeinput[type="range"]::-moz-range-thumb {
    height: 15px;
    width: 15px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid var(--themecolor);
    /*  slider progress trick  */
    box-shadow: -407px 0 0 400px var(--themecolor);
}


.modalslider .rangesec .btngrpsec .btnstyle {
    background: var(--themecolor);
    color: var(--whitetext);
    font-size: 12px;
    width: 100px;
    padding: 5px;
}

.modalslider .rangesec .btngrpsec .btnstyle:hover {
    background: var(--btnhover);
}

.modalslider .rangesec .btngrpsec {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
}

.modalslider .rangesec .rangeinput[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    cursor: pointer;
    outline: none;
    overflow: hidden;
    border-radius: 16px;
    height: 20px;
    margin-bottom: 20px;
}


.modalslider .rangesec .rangeinput[type="range"]::-webkit-slider-runnable-track {
    height: 15px;
    background: var(--bgcolor);
    border-radius: 16px;
}


.modalslider .rangeinput[type="range"]::-moz-range-track {
    height: 15px;
    background: var(--bgcolor);
    border-radius: 16px;
}


.modalslider .rangesec .rangeinput[type="range"]::-webkit-slider-thumb {

    -webkit-appearance: none;
    appearance: none;
    height: 20px;
    width: 20px;
    background-color: var(--whitetext);
    border-radius: 50%;
    border: 1px solid var(--whitetext);
    box-shadow: -407px 0 0 400px var(--themecolor);
    margin-top: -3px;
}


/* Thumb: Firefox */
.modalslider .rangesec .rangeinput[type="range"]::-moz-range-thumb {
    height: 15px;
    width: 15px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid var(--themecolor);
    /*  slider progress trick  */
    box-shadow: -407px 0 0 400px var(--themecolor);
}


.modalslider .rangesec .btngrpsec .btnstyle {
    background: var(--themecolor);
    color: var(--whitetext);
    font-size: 12px;
    width: 100px;
    padding: 5px;
}

.modalslider .rangesec .btngrpsec .btnstyle:hover {
    background: var(--btnhover);
}

.modalslider .rangesec .btngrpsec {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
}

.inputlabelwhite {
    color: var(--whitetext);
    font-size: 14px;
}

.inputlabeltheem {
    color: var(--themecolor);
    font-size: 14px;
}

.balancerownowrap {
    gap: 0px;
    flex-direction: row !important;
}

.logoimg_coin {
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

.connectwalletbtn:disabled {
    background: var(--themecolor);
    color: var(--whitetext);
    border: 1px solid var(--themecolor);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
    opacity: 0.5;
}

.balancerowcol {
    flex-direction: column;
    align-items: flex-start !important;
}

.inputlabelbreak {
    word-break: break-all;
    text-align: left !important;

}
.innerpages {
    padding-top: 50px;
    padding-bottom: 70px;
    min-height: 70vh;
}
.connect_btn_theme {
    background: var(--themecolor);
    white-space: nowrap;
    font-weight: 500;
    color: var(--whitetext);
    border: 1px solid var(--themecolor);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
    transition: none 0s ease 0s;
    padding: 5px 20px;
    border-radius: 11px;
    max-width: 200px;
    height: 50px;
    margin: 20px auto !important;
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 100%; */
}

.connect_btn_theme:hover {
    background: var(--btnhover);
    border: 1px solid var(--btnhover);

}

/* .innerpages [class="container"]{
    max-width: 1200px;
} */
.innerpages .headrow .pagehead {
    font-size: 23px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--whitetext);
    margin-bottom: 5px;
}

.innerpages .headrow .pagedesc {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--themecolor);
}

.innerpages .balancecard {
    background: var(--bgcolor);
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.innerpages .balancecard .labelname {
    font-size: 10px;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--lighttext);
    margin-bottom: 5px;
}

.innerpages .balancecard .labelvalue {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--whitetext);
    margin-bottom: 0px;
}

.innerpages .balancecard .balanceimg {
    width: 25px;

}

.dashcard {
    background: var(--cardbg);
    padding: 20px;
    cursor: pointer;
}

.dashcard .innercard {
    background: var(--bgcolor);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px;
}

.dashcard .innercard.innercardalloc {
    display: grid;
    grid-template-columns: 49% 49%;
    grid-column-gap: 1%;
}

.dashcard .name {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--whitetext);
    margin-bottom: 5px;

}

.dashcard .desc {
    font-size: 13px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--desctext);

}

.dashcard .innercard .labelname {
    font-size: 11px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--whitetext);
    margin-bottom: 5px;
}

.dashcard .innercard .value {
    font-size: 10px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--whitetext);
    margin-bottom: 0px;
}

.dashcard .dashimg {
    /* width: 15px;
    height: 15px;
    object-fit: contain; */
    width: 30px;
    min-width: 30px;
    min-height: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 50%;
}

.dashcard .imgsec {
    background: var(--bgcolor);
    border-radius: 50px;
    padding: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
}

.dashcard .stakebtn {
    background: var(--bgcolor);
    color: var(--themecolor);
    font-size: 13px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    padding: 5px 10px;

}

.dashcard .stakebtn:hover {
    background: var(--themecolor);
    color: var(--bgcolor);

}

.dashcard .topsec {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.approvecard {
    background: var(--cardbg);
}

.approvecard .toptab {
    display: flex;
    justify-content: space-between;
}

.approvecard .toptab .tabname {
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 0px;
    color: var(--whitetext);

}

.approvecard .toptab .activetab {
    background: var(--themecolor);
}

.approvecard .toptab .activetab .tabname {
    color: var(--blacktext);
}

.approvecard .toptab .tabsec {
    padding: 10px;
    cursor: pointer;
}

.approvecard .innercard {
    padding: 15px;
    padding-bottom: 20px;
}

.approvecard .innercard .head {
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--whitetext);
    margin-bottom: 10px;
}

.approvecard .innercard .desc {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--desctext);
    margin-bottom: 5px;
}

.approvecard input {
    background: var(--bgcolor);
    border: none;
    color: #fff;
    font-size: 12px;
}

.approvecard input:focus {
    background: var(--bgcolor);
    border: none;
    box-shadow: none;
    color: #fff;
}

.approvecard .inputsec input {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.approvecard .inputsec .inputspan {
    background: var(--bgcolor);
    border: none;
    color: #fff;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: 2px solid #434343;
    font-size: 12px;
    height: 100%;
    padding: 10px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

}

.approvecard .inputsec .inputspan:hover {
    background: var(--themecolor);
    color: var(--bgcolor);

}

.approvecard .inputsec .balsec {
    text-align: end;
    margin-bottom: 0px;
    font-size: 12px;
    font-weight: 300;
    line-height: 15px;
    letter-spacing: 0em;
    color: var(--whitetext);

}

.approvecard .btnsec .btn {
    background: var(--bgcolor);
    width: 100%;
    color: var(--whitetext);
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.47999998927116394px;
    text-align: center;
    height: 40px;

}

.approvecard .btnsec .btn:hover {
    background: var(--themecolor);
}

.approvecard .grpbtnsec button {
    background: var(--bgcolor);
    border: none;
    font-size: 12px;
}

.approvecard .grpbtnsec button:hover {
    background: var(--themecolor);
}

.approvecard .btnsec .btn:disabled {
    border: none !important;
}

.approvecard .redmsec {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.approvecard .redmsec .redmlabel {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--whitetext);
    margin-bottom: 5px;
}

.approvecard .redmsec .redmbtn {
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--themecolor);
}

.approvecard .redmsec button.activebtn {
    background: var(--themecolor);
}

.approvecard .grpbtnsec .inputsec {
    display: flex;
    align-items: end;
    flex-direction: row;
    gap: 10px;


}

.approvecard .grpbtnsec .inputsec input {
    width: 30px;
    height: 30px;
    padding: 5px;
    outline: 0px !important;
    text-align: center;
}

.approvecard .grpbtnsec .inputsec p {
    color: var(--whitetext);
    font-size: 10px;
    margin-bottom: 3px;

}

.approvecard .listsec .listrow {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

}

.approvecard .listsec .listrow p {
    color: var(--whitetext);
    margin-bottom: 5px;
    font-size: 14px;
}

.innerpages.yieldpage .searchsec .searchcard {
    background: var(--cardbg);
    padding: 15px;
    align-items: center;
    width: 100%;
    justify-content: center;

}

.innerpages .dropdownstyle button {
    background: var(--bgcolor);
    border: none;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.innerpages .dropdownstyle button:active {
    background: var(--bgcolor);
    border: none;
}

.innerpages .dropdownstyle .dropdownmenustyle {
    background: var(--bgcolor);
    border: none;
    width: 100%;
    color: var(--whitetext);
}

.innerpages .dropdownstyle .dropdownmenustyle a {
    color: var(--whitetext);
}

.innerpages .dropdownstyle .dropdownmenustyle a:hover {
    color: var(--whitetext);
    background: var(--themecolor);
}

.innerpages .toggleswitch input {
    background: var(--themecolor);
    border: none;

}

.innerpages .searchsec .inputsec input {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.innerpages .searchsec .inputsec .inputspan {
    background: var(--bgcolor);
    border: none;
    color: #fff;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    font-size: 12px;
    height: 100%;
    padding: 10px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

}

.innerpages .searchsec .inputsec .inputspan svg {
    font-size: 20px;
}

.innerpages .searchsec .inputsec .inputspan:hover {
    background: var(--themecolor);
    color: var(--bgcolor);

}


.innerpages .searchsec .inputsec input {
    background: var(--bgcolor);
    border: none;
    color: #fff;
    font-size: 12px;
    height: 40px;
}

.innerpages .searchsec .inputsec input:focus {
    background: var(--bgcolor);
    border: none;
    box-shadow: none;
    color: #fff;
}

.innerpages .searchsec .inputsec input::placeholder {
    color: #fff;

}

.innerpages .searchsec {
    display: flex;
    flex-direction: row;
}

.innerpages.yieldpage .togglesec {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.innerpages.yieldpage .togglesec .spanlabel {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--whitetext);

}

.innerpages .roundbg {
    background: var(--cardbg);
    padding: 15px;
}

.innerpages.roundpage .searchcard {
    width: 100%;
    align-items: center;
}

.innerpages.tabledetailpage .backbtn {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #fff;


}

.innerpages.tabledetailpage .backbtn {
    display: flex;
    gap: 10px;
    align-items: center;
}

.innerpages.tabledetailpage .labelsec {
    background: var(--cardbg);
    display: flex;
    gap: 10px;
    align-items: center;
    width: auto;
    border-radius: 20px;
    margin-top: 10px;
    padding-left: 0px;
    flex-wrap: wrap;
}

.innerpages.tabledetailpage .labelsec .labelname {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--blacktext);
    background: var(--themecolor);
    border-radius: 20px;
    padding: 5px 10px;
    margin-bottom: 0px;


}

.innerpages.tabledetailpage .labelsec .linkname {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    padding: 5px 10px;
    color: var(--whitetext);

}

.innerpages.tabledetailpage .tablename {
    font-size: 25px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--whitetext);

}

.innerpages.tabledetailpage .tabledesc {
    font-size: 14px;
    font-weight: 500;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--whitetext);

}

.innerpages.tabledetailpage .tableimg {
    width: 180px;
    height: 180px;
    border-radius: 50px;
    object-fit: cover;
}

.innerpages.tabledetailpage .topdetailsec .imgsec {
    display: flex;
    align-items: center;
    justify-content: center;

}


.innerpages.tabledetailpage .socialsec .sociallist {
    list-style: none;
}

.innerpages.tabledetailpage .socialsec .sociallist ul {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 10px;
    padding-left: 0px;
}

.innerpages.tabledetailpage .socialsec .sociallist li:hover {
    background: var(--themecolor);
}

.innerpages.tabledetailpage .socialsec .sociallist li svg {
    fill: var(--bgcolor);
}

.innerpages.tabledetailpage .socialsec .sociallist li {
    background: #282d37;
    border-radius: 50px;
    padding: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
}

.innerpages.tabledetailpage .socialsec .labelname {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--whitetext);

}

.innerpages.tabledetailpage .pricerow .typerow {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}

.innerpages.tabledetailpage .pricerow .typerow .typename {
    font-size: 14x;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--themecolor);

}

.innerpages.tabledetailpage .pricerow .roundname {
    font-size: 22px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--whitetext);

}

.innerpages.tabledetailpage .valuerow .value {
    font-size: 22px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--themecolor);
    margin-bottom: 8px;

}

.innerpages.tabledetailpage .valuerow .labelvalue {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--whitetext);

}

.innerpages.tabledetailpage .valuerow .valuefirstrow {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.innerpages.tabledetailpage .pricerow .pageimgsec {
    position: relative;
    width: 100%;
    height: 100%;
    background: url('../images/swapbg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
}

.innerpages.tabledetailpage .pricerow .pageimgsec .pagebtn {
    width: 80%;
    height: 40px;
    bottom: 10px;
    z-index: 1;
    background: var(--themecolor);
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--blacktext);


}

.innerpages.tabledetailpage .pricerow .pageimgsec1 {
    position: relative;
    width: 100%;
    height: 100%;
    background: url('../images/liqbg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
}

.innerpages.tabledetailpage .pricerow .pageimgsec1 .pagebtn {
    width: 80%;
    height: 40px;
    bottom: 10px;
    z-index: 1;
    background: var(--themecolor);
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--blacktext);

}

.innerpages.tabledetailpage .pricerow .pageimgsec1 .pagebtn:hover,
.innerpages.tabledetailpage .pricerow .pageimgsec .pagebtn:hover {
    background-color: var(--bgcolor);
    color: var(--whitetext);

}

.innerpages .datatablestyle {
    background: var(--cardbg);
    width: 100%;
}

.innerpages .datatablestyle thead {
    border-bottom: 1px solid var(--bgcolor);
    height: 45px;
}

.innerpages .datatablestyle thead tr th {
    font-size: 12px;
    font-weight: 300;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--whitetext);
    padding: 10px;

}

.innerpages .datatablestyle tbody tr {
    height: 60px;
    cursor: pointer;
}

.innerpages .datatablestylenohover tbody tr {
    cursor: default;

}

.innerpages .datatablestyle tbody tr td {
    color: var(--whitetext);
    padding: 10px;
    font-size: 14px;
    min-width: 110px;

}

.innerpages .tablerow .tablename {
    background: var(--cardbg);
    font-size: 18px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--whitetext);
    width: 120px;
    padding: 10px;
    text-align: center;
    margin-bottom: 0px;

}

.innerpages .tabstyle .tablename span {
    font-size: 10px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--themecolor);
}


.innerpages.launchpaddetail .toprow .breadcrum {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--themecolor);

}

.innerpages.launchpaddetail .toprow .pfsec {
    display: flex;
    gap: 10px;
}

.innerpages.launchpaddetail .toprow .pfsec {
    display: flex;
    gap: 10px;
    align-items: center;
}

.innerpages.launchpaddetail .toprow .pfsec .name {
    font-size: 21px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--whitetext);
    margin-bottom: 0px;

}

.innerpages.launchpaddetail .toprow .pfsec .pfimg {
    width: 40px;
    height: 40px;
    border-radius: 50px;
    object-fit: cover;
}

.innerpages.launchpaddetail .toprow .desc {
    font-size: 14px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--whitetext);

}

.innerpages.launchpaddetail .toprow .productimg {
    width: 180px;
    height: 180px;
}

.innerpages.launchpaddetail .toprow .extlink {
    color: #fff;
    text-decoration: none;
    font-size: 15px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    background: var(--bgcolor);
    padding: 10px;

}

.innerpages.launchpaddetail .toprow .extlink:hover {
    background: var(--themecolor);
}

.innerpages.launchpaddetail .inforow {
    border: var(--greytext) 1px solid;
    align-items: center;
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.innerpages.launchpaddetail .inforow .infodesc {
    font-size: 13px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--whitetext);
    margin-bottom: 0px;

}

.innerpages.launchpaddetail .inforow .svgsec {
    text-align: center;
}

.innerpages.launchpaddetail .inforow .svgsec svg {
    width: 40px;
    height: 40px;

}

.innerpages.launchpaddetail .statusrow .statusline {
    height: 5px;
    width: 100%;
    background: var(--progressbar);
}

.innerpages.launchpaddetail .statusrow .statusline.active {
    background: var(--themecolor) !important;
}

.innerpages.launchpaddetail .statusrow .statuslabel {
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--whitetext);
    margin-top: 10px;
}

.innerpages.launchpaddetail .pricesec .leftsec .statusname {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--themecolor);

}

.innerpages.launchpaddetail .pricesec .leftsec .name {
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--whitetext);

}

.innerpages.launchpaddetail .pricesec .valuerows .value {
    font-size: 16px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--themecolor);
    margin-bottom: 5px;

}

.innerpages.launchpaddetail .pricesec .valuerows .labelvalue {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--whitetext);

}

.innerpages.launchpaddetail .pricesec .valuerows .valueinner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.innerpages.launchpaddetail .stagesec .toprow {
    text-align: center;
}

.innerpages.launchpaddetail .stagesec .toprow .label {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    color: var(--themecolor);
    margin-bottom: 10px;
}

.innerpages.launchpaddetail .stagesec .toprow .labeldesc {
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    color: var(--whitetext);
}

.innerpages.launchpaddetail .stagesec .stagedetail .stagename {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    color: var(--whitetext);
    margin-bottom: 10px;
}

.innerpages.launchpaddetail .stagesec {
    text-align: center;
}

.innerpages.launchpaddetail .stagesec .stagedetail .stageimgs {
    text-align: center;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    margin-bottom: 10px;
}

.innerpages.launchpaddetail .stagesec .stagedetail .stagedesc {
    text-align: left;
    font-size: 13px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    color: var(--whitetext);
}

.innerpages.launchpaddetail .inforow.warning {
    border: var(--redtext) 1px solid;
}

.innerpages.launchpaddetail .inforow.warning .infodesc {
    color: var(--redtext);
}

.innerpages.tabledetailpage .detailtablepools {
    display: flex;
    align-items: center;
    gap: 10px;
}

.innerpages.tabledetailpage .detailtablepools img {
    width: 30px;
    height: 30px;
}

.innerpages.tabledetailpage .detailtablepools span {
    color: var(--themecolor);
    font-size: 10px;
    margin-left: 2px;
}

.innerpages .tabstyle {
    background: var(--cardbg);
}

.innerpages.launchpadpage .launchname {
    display: flex;
    align-items: center;
    gap: 10px;
}

.innerpages.launchpadpage .launchname img {
    width: 40px;
    height: 40px;
    border-radius: 50px;
    object-fit: cover;

}

.innerpages.launchpadpage .launchname .name {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--whitetext);
    margin-bottom: 0px;

}

.innerpages.launchpadpage .launchname .label {
    font-size: 10px;
    font-weight: 200;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--whitetext);
    margin-bottom: 0px;
}

@media screen and (max-width:575px) {
    .innerpages.launchpaddetail .pricesec .valuerows .value {
        font-size: 14px !important;
    }

    .flex_col_mob {
        flex-direction: column;
    }

    .innerpages.launchpaddetail .inforow {
        display: block;
    }
    .innerpages.launchpaddetail .inforow .infodesc
    {
        text-align: center;
    }

}

@media screen and (max-width:991px) {

    .innerpages.tabledetailpage .pricerow .pageimgsec,
    .innerpages.tabledetailpage .pricerow .pageimgsec1 {
        height: 350px;
    }
}

.textwhiteyum {
    color: #fff;
    font-size: 12px;
}

.textthemeyum {
    color: var(--themecolor);
    font-size: 12px;
}




.pooltable .imgsec {
    display: flex;
    gap: 30px;
    align-items: center;
}

.pooltable .imgsec .leftimgsec {
    position: relative;
}

.pooltable .imgsec .leftimgsec .fromimg {
    position: relative;
    z-index: 2;
    width: 25px;
    height: 25px;
    object-fit: contain;

}

.pooltable .imgsec .leftimgsec .toimg {
    position: absolute;
    z-index: 0;
    right: -12px;
    z-index: 1;
    top: 8px;
    width: 25px;
    height: 25px;
    object-fit: contain;
}

.pooltable .coinname {
    font-size: 14px;
}

.pooltable .coinnamegrey {
    font-size: 14px;
    color: var(--greytext);
}


.pooltable.tooltip_style {
    padding: 16px;
    font-size: 16px;
    line-height: 130%;
    border-radius: 11px;
    max-width: 320px;
    z-index: 101;
    background: rgb(35, 40, 51);
    color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 2px, rgba(14, 14, 44, 0.1) 0px 4px 12px -8px;
}

.pooltable .tooltip_button {
    background-color: transparent !important;
    border: none;
    border-radius: 0px;
    color: #fff;
    padding: 0px;
    margin-left: 10px;
}

.pooltable .themeapr {
    color: var(--themecolor);
}

.pooltable {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.pooltablewidth td:first-child,
.pooltablewidth th:first-child {
    min-width: 200px !important;
}

.pooltablewidth td:last-child,
.pooltablewidth th:last-child {
    min-width: 120px !important;
}

.pooltablewidth td:nth-child(3),
.pooltablewidth th:nth-child(3) {
    min-width: 200px !important;
}

.yieldtablewidth td:nth-child(2),
.yieldtablewidth th:nth-child(2),
.yieldtablewidth td:nth-child(4),
.yieldtablewidth th:nth-child(4) {
    min-width: 150px !important;
}



.yieldtablewidth td:last-child,
.yieldtablewidth th:last-child {
    min-width: 100px !important;
}


.yieldtablewidth td:first-child,
.yieldtablewidth th:first-child {
    min-width: 150px !important;
}


.yieldadvtablewidth td:nth-child(2),
.yieldadvtablewidth th:nth-child(2) {
    min-width: 150px !important;
}


.yieldadvtablewidth td:nth-child(4),
.yieldadvtablewidth th:nth-child(4) {
    min-width: 300px !important;
}


.yieldadvtablewidth td:nth-child(5),
.yieldadvtablewidth th:nth-child(5) {
    min-width: 200px !important;
}




.yieldadvtablewidth td:last-child,
.yieldadvtablewidth th:last-child {
    min-width: 100px !important;
}


.yieldadvtablewidth td:first-child,
.yieldadvtablewidth th:first-child {
    min-width: 150px !important;
}



.tooltip_button_active {
    color: var(--themecolor) !important;
}

.tooltip_button_inactive {
    color: #ccc !important;
}

.btn_trans_icon_table {
    position: relative;
    z-index: 1 !important;
}

.cursor_pointer_column {
    cursor: pointer;
}


.btnicondelete {
    background: var(--themecolor);
    white-space: nowrap;
    font-weight: 600;
    font-size: 12px;
    color: var(--whitetext);
    border: 1px solid var(--themecolor);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
    transition: none 0s ease 0s;
    padding-top: 3px;
    width: 80px;
    padding-bottom: 3px;
    border-radius: 11px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

.btnicondelete:hover {
    background: rgb(119, 209, 255) !important;
    border: 1px solid rgb(119, 209, 255) !important;
    color: rgb(255, 255, 255) !important;
    transition: none 0s ease 0s !important;
}


@media only screen and (min-width:1200px) {
    .col_3custom {
        width: 22% !important;
    }
}


.stakebtnicon {
    background: var(--bgcolor);
    color: var(--themecolor);
    font-size: 13px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    padding: 5px 10px;

}

.stakebtnicon:disabled {
    background: var(--bgcolor);
    color: var(--themecolor);
    opacity: 0.5;
}

.stakebtnicon:hover {
    background: var(--themecolor);
    color: var(--bgcolor);

}

.headfelx {
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.headfelx .leftimgsec {
    position: relative;
}

.headfelx .leftimgsec .fromimg {
    position: relative;
    z-index: 2;
    width: 25px;
    height: 25px;
    object-fit: contain;

}

.headfelx .leftimgsec .toimg {
    position: absolute;
    z-index: 0;
    right: -12px;
    z-index: 1;
    top: 8px;
    width: 25px;
    height: 25px;
    object-fit: contain;
}


.coinnamewhitenew {
    font-size: 14px;
    color: var(--whitetext);
    margin-left: 20px;
}

.coinnamegreynew {
    font-size: 14px;
    color: var(--greytext);

}


.tropyhyblack {
    width: 30px;
    height: 30px;
    background-color: var(--bgcolor);
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.infocircle {
    color: #fff;
    font-size: 20px;
}

.themetime {
    color: var(--themecolor);
}

.yiled_filter .labelname {
    color: var(--themecolor);
    font-size: 14px;
}

.themelighttext {
    color: #ccc;
}

.tetxwhitebold {
    color: #fff;
}

.warning_border {
    border: 1px solid var(--themecolor);
    padding: 10px;
    border-radius: 5px;
}

.themeicon {
    color: var(--themecolor);
    margin-right: 10px;
    font-size: 18px;
    min-width: 20px;
}

.labelswarn {
    color: var(--whitetext);
    font-size: 14px;
    text-align: center;
}


.connect_btn_theme {
    background: var(--themecolor);
    white-space: nowrap;
    font-weight: 500;
    color: var(--whitetext);
    border: 1px solid var(--themecolor);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
    transition: none 0s ease 0s;
    padding: 5px 20px;
    border-radius: 11px;
    max-width: 200px;
    height: 50px;
    /* width: 100%; */
}

.connect_btn_theme:disabled {
    background: var(--themecolor) !important;
    color: var(--whitetext) !important;
    border: 1px solid var(--themecolor) !important;
    opacity: 0.5;
}

.connect_btn_theme:hover {
    background: var(--btnhover);
    border: 1px solid var(--btnhover);

}



.connect_btn_grey {
    background: var(--btnhover);
    white-space: nowrap;
    font-weight: 500;
    color: var(--blacktext);
    border: 1px solid var(--btnhover);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
    transition: none 0s ease 0s;
    padding: 5px 20px;
    border-radius: 11px;
    max-width: 200px;
    height: 50px;
    /* width: 100%; */
}

.connect_btn_grey:hover {
    background: var(--themecolor);
    border: 1px solid var(--themecolor);
    color: var(--whitetext);

}

/* Range Slider as a Single Thumb Slider */
.single_thumb [class="range-slider__thumb"][data-lower] {
    width: 0 !important;
}

.single_thumb {
    height: 5px !important;
}

.single_thumb [class="range-slider__range"] {
    border-radius: 6px;
    background: var(--themecolor) !important;
}

.single_thumb [class="range-slider__thumb"] {
    background: var(--themecolor) !important;
    width: 15px !important;
    height: 15px !important;

}


.input_table,
.input_table:focus {
    background-color: var(--bgcolor);
    border: none;
    outline: 0px;
    color: #fff;
    box-shadow: 0 0 0 0rem rgba(13, 110, 253, 0.25) !important;
}

.input_table::placeholder {

    color: #ccc;
}

.maxbonusbtn {
    background-color: transparent;
    border: none;
    color: var(--themecolor);
    font-size: 14px;
}

.maxbonusbtn:hover {
    color: var(--whitetext);
}


.spantitletheme {
    color: var(--themecolor);
}

.connect_btn_new {
    background: var(--themecolor);
    white-space: nowrap;
    font-weight: 500;
    color: var(--whitetext);
    border: 1px solid var(--themecolor);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
    transition: none 0s ease 0s;
    padding: 10px;
    border-radius: 11px;
    width: 100%;
}

.connect_btn_new:hover {
    background: var(--btnhover);
    border: 1px solid var(--btnhover);

}

.pageheadnew {
    font-size: 23px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--whitetext);
    margin-bottom: 5px;
}

.datelaunchpad {
    color: var(--whitetext);
    font-size: 12px;
}

.datelaunchpadtheme {
    color: var(--themecolor);
}

.opentext {
    font-size: 12px !important;
    color: var(--themecolor)
}

.opentext {
    font-size: 11px !important;
    color: var(--themecolor);
    padding-left: 10px;

}

.closetext {
    font-size: 11px !important;
    color: #dc3545;
    padding-left: 10px;

}

.perkcardimg {
    width: 30px;
    height: 30px;
    object-fit: cover;
    min-width: 30px;
    min-height: 30px;
    border-radius: 50%;
}

.nameperk {
    font-size: 16px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--whitetext);
    margin-bottom: 5px;
}


.loadbtn {
    background: var(--themecolor);
    white-space: nowrap;
    font-weight: 600;
    font-size: 16px;
    color: var(--whitetext);
    border: 1px solid var(--themecolor);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
    transition: none 0s ease 0s;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 11px;
    height: 48px;
}

.loadbtn:hover {
    background: rgb(119, 209, 255) !important;
    border: 1px solid rgb(119, 209, 255) !important;
    color: rgb(255, 255, 255) !important;
    transition: none 0s ease 0s !important;
}

.progressclick {
    cursor: pointer;
}

.progressclick.disabledclick {

    cursor: no-drop !important;

}

.progressclick.disabledclick .statusline,
.progressclick.disabledclick:hover .statusline,
.progressclick.disabledclick:focus .statusline {
    background: var(--progressbar) !important;
}

.progressclick:hover .statusline {
    background: var(--themecolor) !important;
}



/* CUSOTM TABLE CSS */
.cutomtablereact th
{
    color: var(--whitetext);
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 15px;
    padding: 10px;
    text-align: left;
}
.cutomtablereact thead
{
    border-bottom: 1px solid var(--bgcolor);
    height: 45px;
}
.cutomtablereact tbody
{
    background-color: transparent;
}
.cutomtablereact tbody tr 
{
    border:none;
}
.cutomtablereact tbody tr td {
    color: var(--whitetext);
    font-size: 14px;
    min-width: 110px;
    padding: 10px;
    background-color: transparent;
    border:none;
    vertical-align: middle;

}

.customlaunchpad th:first-child,
.customlaunchpadtd:first-child
{
    min-width: 150px;
}

.customlaunchpad th:nth-child(5),
.customlaunchpadtd:nth-child(5)
{
    min-width: 150px;
}
.pointerrow
{
    cursor: pointer;
}
.tablefiltercustom
{
    margin: 15px;
    padding-top: 15px;
}
.tablefiltercustom input::placeholder
{
    color: var(--whitetext);
}
.tablefiltercustom input,
.tablefiltercustom input:focus
{
    width: 100% !important;
    background: var(--bgcolor);
    border: none;
    height: 35px;
    color: var(--whitetext);
    background-image: url('../images/searchicon.png');
    background-repeat: no-repeat;
    background-size: 15px;
    background-position: top 10px right 10px;
}


.cutomtablereactres::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

.cutomtablereactres::-webkit-scrollbar
{
  width: 8px;
  background-color: #F5F5F5;
  height: 8px;
  border-radius: 20px;
}

.cutomtablereactres::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: var(--themecolor);
}

.themeloader
{
color:var(--themecolor);
font-size: 18px;
text-align: center;
min-width: 200px;
}

.labelvaluebrk
{
    word-break: break-all;
}

.valuebrknew
{
    max-width: 90%;
    word-break: break-all;
}
.farmcard{
    background: var(--cardbg);
    padding: 20px;
    padding-top: 25px;
    height: 100%;
}
.farmcard .toprow{
    display: flex;
    justify-content: space-between;
}
.farmcard .toprow .leftimgsec {
    position: relative;
}
.farmcard .toprow .leftimgsec .fromimg {
    position: relative;
    z-index: 2;
    width: 40px;
    height: 40px;
    object-fit: contain;

}
.farmcard .toprow .leftimgsec .toimg {
    position: absolute;
    z-index: 0;
    right: -15px;
    z-index: 1;
    top: 10px;
    width: 40px;
    height: 40px;
    object-fit: contain;
}
.farmcard .toprow .rightsec .coinname{
    font-size: 18px;
    font-weight: 900;
    color: var(--whitetext);
    margin-bottom: 5px;
    text-align: end;
}
.farmcard .toprow .rightsec .labelsec{
    display: flex;
    gap: 10px;
}
.farmcard .toprow .rightsec .labelsec .corelabel{
    background: var(--themecolor);
    border: 2px solid var(--whitetext);
    border-radius: 10px;
    color: var(--whitetext);
    padding: 2px 10px;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap:5px;
    margin-bottom: 0px;
}
.farmcard .toprow .rightsec .labelsec .multilabel{
    background: var(--themecolor);
    padding: 5px 10px;
    color: var(--whitetext);
    font-size: 12px;
    border-radius: 10px;
    margin-bottom: 0px;
    font-weight: 700;
    
}
.farmcard .toprow{
    margin-bottom: 15px;
}
.farmcard .detaillist{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}
.farmcard .detaillist .leftlabel{
    color: var(--whitetext);
    font-size: 16px;
    margin-bottom: 0px;
    font-weight: 500;
}
.farmcard .detaillist .rightlabel{
    color: var(--whitetext);
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 0px;
}
.farmcard .harvestsec .label{
    font-size: 12px;
    color: var(--whitetext);

}
.farmcard .btnsec .btnlabel{
    font-size: 12px;
    color: var(--whitetext); 
    font-weight: 600;
}
.farmcard .btnsec .walletbtn{
    background: var(--themecolor);
    white-space: nowrap;
    font-weight: 700;
    color: var(--whitetext);
    border: 1px solid var(--themecolor);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
    transition: none 0s ease 0s;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 11px;
    height: 40px;
    width: 100%;
}

.farmcard .btnsec .walletbtn:hover {
    background: var(--btnhover) ;
    border: 1px solid var(--btnhover) ;
    color: rgb(255, 255, 255) ;
    transition: none 0s ease 0s ;

}
.farmcard .harvestsec .harvestrow{
    display: flex;
    gap: 10px;
    justify-content: space-between;
}
.farmcard .harvestsec .harvestrow .label{
    font-weight: 600;
    font-size: 16px;
    color: var(--whitetext);
}
.farmcard .harvestsec .harvestbtn{
    background: var(--themecolor);
    white-space: nowrap;
    font-weight: 700;
    color: var(--whitetext);
    border: 1px solid var(--themecolor);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
    transition: none 0s ease 0s;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 11px;
    height: 40px;
    width: 90px;
}
.farmcard .harvestsec .harvestbtn:hover{
    background: var(--btnhover) ;
    border: 1px solid var(--btnhover) ;
    color: rgb(255, 255, 255) ;
    transition: none 0s ease 0s ;
}
.farmcard .harvestrow{
    margin-bottom: 10px;
}

.farmcard .extradetailsec {
    margin-top: 30px;
}
.farmcard .extradetailsec .label{
    color: var(--whitetext);
    font-size: 16px;
    text-align: center;
    cursor: pointer;
}
.farmcard .extradetailsec .listrow{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.farmcard .extradetailsec .listrow p{
        color: var(--whitetext);
        font-size: 16px;
        margin-bottom: 5px;
}
.farmcard .extradetailsec  .contractlink{
    color: var(--whitetext);
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
}
.farmcard .extradetailsec  .contractlink:hover{
    color: var(--themecolor);
}
.farmcard .detaillist .calculatorbtn:hover{
    opacity: 0.65;
    cursor: pointer;

}

.labelsm{
    font-weight: 600;
    font-size: 12px;
    color: var(--whitetext);
    margin-bottom: 0px;
}


.btnicon
{
    background-color: #1c2029;
    color:#fff;
    border-radius: 0px;
    padding: 8px 13px;
    font-size: 12px;
    font-weight: 600;
}
.btnicon:disabled
{
    background-color: #1c2029;
    color:#fff;
    opacity: 0.4;
    border-color: #393939;
}


.btnicon:hover,.btnicon:focus
{
    background: rgba(254, 128, 201, 0.15);
    color:#fff;
}


.declabel{
    font-size: 14px;
    color: var(--themecolor);
    text-align:center;
    font-weight: 600;
    text-decoration: none;
    background: transparent;
    border: none;
}
.declabel:hover{
    color: var(--whitetext);

}

.brakvaltext
{
    word-break: break-all;
}
.modalstyle .modalheader {
    border-bottom: none;
}

.modalstyle .modalbody {
    border-bottom: none;
}

.modalstyle [class="modal-content"] {
    background: var(--cardbg);
    /* box-shadow: var(--themecolor) -1px 0px 14px; */
    /* border: 1px solid var(--themecolor); */
    min-width: 320px;
    max-width: 420px;
    margin: auto;
    border-radius: 0px;
    width: 100%;
    /* border-top-left-radius: 30px !important; */
}

.modalstyle .modalfooter{
    padding: 20px;
    border-top: 0px;
}


.modalstyle .modalheader .modaltitle {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.1;
    color: var(--whitetext);

}
.modalstyle .modalheader{
    display: flex;
    justify-content: space-between;
    padding: 25px;
}
.modalstyle .modalheader .closebtn{
    background: none;
    padding: 0px;
    border: none;
    color: var(--whitetext);
    cursor: pointer;
}
.modalstyle .modalbody {
    /* display: flex;
    gap: 20px;
    justify-content: space-evenly; */
    padding:  25px;

}
.modalstyle .modalbody .label{
    font-size: 12px;
    color: var(--whitetext);
}


.modalstyle .modalfooter .footerdesc{
    color: var(--whitetext);
    text-align: center;
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 10px;
}
.modalstyle .modalbody .innerhead{
    color: var(--themecolor);
    font-size: 14px;
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: 24px;
    text-transform: uppercase;
}

.modalstyle .modalbody .innerheadwhite
{
    color: var(--whitetext);
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 10px;
}

.innerheadwhitesm
{
    color: var(--whitetext);
    font-size: 13px;
    line-height: 1.5;
}
.innerheadthemesm
{
    color: var(--themecolor);
    font-size: 13px;
    line-height: 1.5;
}



.modalstyle
{
    padding-right: 8px !important;
}

.modalstyle .modalbody ul{
    list-style:none;
    padding-left: 0px;
}

@media screen and (max-width:400px) {
    .modalstyle .modalbody .innerheadwhite
{
    font-size: 14px;
}


    .modalstyle [class="modal-content"] {
        min-width: 280px;
        max-width: 280px;
    }
   
}

@media screen and (max-width:575px)
{
    .buttonstyle_ul

{
    padding-left: 9px !important;
    padding-right: 9px !important;
}
}


.buttonstyle_ul:disabled
{
    background-color: #1c2029;
    opacity: 0.5 !important;
    color:#fff;
    border:none;

}

.buttonstyle_ul
{
    background-color: #1c2029;
    color:#fff;
    border-radius: 0px;
    padding: 8px 13px;
    font-size: 12px;
    font-weight: 600;
}



.buttonstyle_ul:hover,.buttonstyle_ul:focus
{
    background: rgba(254, 128, 201, 0.15);
    color:#fff;
}

.form-control:focus
{
    box-shadow: 0 0 0 0.25rem rgb(72 75 81 / 12%) !important;
}







.themelink
{
    color:var(--themecolor);
    text-decoration: none;
    font-size: 13px;
}

.themelink:hover
{
    color:var(--whitetext);
}
.textwhitedesc
{
    color:var(--whitetext);
    font-size: 14px;
}
.textgreydesc
{
    color:var(--lighttext);
    font-size: 14px;
}


.textwhitedesclg
{
    color:var(--whitetext);
    font-size: 18px;
    font-weight: 700;
    text-align: center;
}
.textwhitedesclg span
{
    color:var(--themecolor);
    padding-left: 10px;
    padding-right: 10px;
}
.textgreydesclg
{
    color:var(--lighttext);
    font-size: 16px;
    text-align: center;
}



 .name{
    color: var(--whitetext);
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 5px;
}
.desc{
    color: var(--whitetext);
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 0px;
}



.createaccordion [class="accordion-header"] button {
    background: var(--bgcolor);
    border: none;
    box-shadow: none;
}

.createaccordion [class="accordion-item"] {
    border: none;
    box-shadow: none;

}

.createaccordion [class="accordion-item"]:hover {
    box-shadow: none;
}

.createaccordion [class="accordion-header"] .labelhead {
    color: var(--whitetext);
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
}

.createaccordion [class="accordion-header"] .balance {
    color: var(--themecolor);
    font-size: 14px;
    font-weight: 500;
}



.createaccordion [class="accordion-body"],.createaccordion [class="accordion"],
.createaccordion [class="accordion-item"] {
    background: var(--bgcolor);
}

.createaccordion [class="accordion-button"]::after,
.createaccordion [class="accordion-button collapsed"]::after

{
color:#fff !important;
filter: brightness(0) invert(1) !important;
}
.createaccordion .label {
    color: var(--whitetext);
    font-size: 14px;
    text-align: center;
    margin-bottom: 5px;
}
.createaccordion .labelflex
{
flex-direction: column;
}
.copybtntrans
{
    background-color: transparent;
    border:none !important;
    max-width: 50px !important;
    color:var(--whitetext);
    padding-right: 0px;
    padding-top: 0px;
}
.copybtntrans:hover
{
    color:var(--themecolor);
}

.secrow {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}
.labels {
    color: var(--themecolor);
    font-size: 14px;
    text-align: center;
}
.innerheadwhitetitle
{
    color: var(--whitetext);
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 10px;
    background-color: var(--bgcolor);
    padding: 8px 10px;
    border-radius: 2px;
}


.connectwalletbtn {
    background: var(--themecolor);
    white-space: nowrap;
    font-weight: 600;
    font-size: 16px;
    color: var(--whitetext);
    border: 1px solid var(--themecolor);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
    transition: none 0s ease 0s;
    padding-top: 5px;
    width: 100%;
    padding-bottom: 5px;
    border-radius: 11px;
    height: 48px;
}
.connectwalletbtn:disabled
{
    background: var(--themecolor) !important;
    opacity: 0.5;
    color: var(--whitetext) !important;
    border: 1px solid var(--themecolor) !important; 
}
.connectwalletbtn:hover
{
    background: rgb(119, 209, 255) !important;
    border: 1px solid rgb(119, 209, 255) !important;
    color: rgb(255, 255, 255) !important;
    transition: none 0s ease 0s !important;
}

.cancelbtn {
    background: var(--cancel);
    white-space: nowrap;
    font-weight: 600;
    font-size: 16px;
    color: var(--whitetext);
    border: 1px solid var(--cancel);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
    transition: none 0s ease 0s;
    padding-top: 5px;
    width: 100%;
    padding-bottom: 5px;
    border-radius: 11px;
    height: 48px;
}
.cancelbtn:disabled
{
    background: var(--cancel) !important;
    color: var(--whitetext);
    border: 1px solid var(--cancel);
    opacity: 0.5;
}
.cancelbtn:hover
{
    background: rgb(119, 209, 255) !important;
    border: 1px solid rgb(119, 209, 255) !important;
    color: rgb(255, 255, 255) !important;
    transition: none 0s ease 0s !important;
}


@media screen and (max-width:575px)
{
    .valuecardsec .secrow
    {
        flex-direction: column;
        align-items: flex-start;
    }
}

 .leftimgsec{
    position: relative;
max-width: 30px;
}
.leftimgsec .fromimg {
    position: relative;
    z-index: 2;
    width: 25px;
    height: 25px;
    object-fit: contain;

}
.leftimgsec .toimg {
    position: absolute;
    z-index: 0;
    right: -12px;
    z-index: 1;
    top: 8px;
    width: 25px;
    height: 25px;
    object-fit: contain;
}
 .coinname{
    font-size: 14px;
    font-weight: 900;
    color: var(--whitetext);
    margin-bottom: 0px;
    text-align: end;
    margin-left: 20px;
}
.imgsec
{
    display: flex;
    align-items: center;
    justify-content: center;
}
.loadspinner
{
    font-size: 20px;
    color:var(--whitetext);
    margin-top: 50px;
    animation: mymove 5s infinite;
    /* transform: rotate(360deg); */
}

@keyframes mymove {
    50% {transform: rotate(360deg);}
  }


  .declabel{
    font-size: 14px;
    color: var(--themecolor);
    text-align:center;
    font-weight: 600;
    text-decoration: none;
    background: transparent;
    border: none;
}
.declabel:hover{
    color: var(--whitetext);

}

.circleplus
{
    color:#ace966;
    font-size: 30px;
}

.coinnamegrey
{
    font-size: 14px;
    color:var(--greytext);
    margin-left: 20px;
}

.tokenvalue
{
    color:#fff;
    font-size: 20px;
    text-align: center;
    font-weight: 700;
    margin-top: 20px;
}

.tokenvalue span{
    color:var(--themecolor);
}

.tokenvaluedesc
{
    font-size: 14px;
    color:#888;
    text-align: center;
}

.tokenvaluedesctheme
{
    font-size: 16px;
    color:var(--themecolor);
    font-weight: 700;
    text-align: center;
}
.tokenvaluedesc span
{
    color:var(--whitetext);
    font-size: 12px;
    padding-left: 3px;
    padding-right: 3px;
}
.themeicon
{
    color:var(--themecolor);
    margin-right: 10px;
    font-size: 18px;
    min-width: 20px;
}

.invisibleicon
{
    color:#888;
    margin-right: 10px;
    font-size: 18px;
}
.hrstyle
{
    border-color:#5d5c5c !important;
    opacity:1;
    margin-top: 10px !important;
}


.inputsec input, .inputsec input:hover, .inputsec input:focus {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    background: var(--bgcolor);
    border: none;
    color: #fff;
    font-size: 12px;
}

.inputsec .inputspan {
    background: var(--bgcolor);
    border: none;
    color: #fff;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: 2px solid #434343;
    font-size: 12px;
    height: 100%;
    padding: 10px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

}

.inputsec .inputspan:hover {
    background: var(--themecolor);
    color: var(--bgcolor);

}

.inputsec .balsec {
    text-align: end;
    margin-bottom: 0px;
    font-size: 12px;
    font-weight: 300;
    line-height: 15px;
    letter-spacing: 0em;
    color: var(--whitetext);

}

.warning_border
{
    border:1px solid var(--themecolor);
    padding: 10px;
    border-radius: 5px;
}
.tropyhytheme
{
    width: 30px;
    height: 30px;
    background-color: var(--themecolor);
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color:#fff;
}
.tropyhyblack
{
    width: 30px;
    height: 30px;
    background-color: var(--bgcolor);
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color:#fff;
}

.innerheadwhitesm span,.innerheadthemesm span
{
    color:#888 !important;
}


.tabstyletabs
{
border:none;
display: flex;
    align-items: center;
    justify-content: space-between;
}

.tabstyletabs [class="nav-link"]
{
background-color: var(--bgcolor);
color:#fff;
border-radius: 12px;
width: 100%;
font-weight: 700;
}
.tabstyletabs [class="nav-item"]
{
width: 45%;
}
.tabstyletabs [class="nav-link active"],
.tabstyletabs [class="nav-link"]:hover,
.tabstyletabs [class="nav-link"]:focus

{
background-color: var(--whitetext) !important;
color:var(--blacktext);
border-radius: 12px;
font-weight: 700;

width: 100%;


}

.innerheadthemesmbreak
{
    word-break: break-all;
}

.innerheadwhitesmbrkval
{
    word-break: break-all;
}